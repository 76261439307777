<ng-container *transloco="let t, read: 'crag.crag'">

  <ng-container *ngIf="crag">

    <h4>{{ t('gradeDistribution') }}:</h4>
    <lc-grade-distribution-bar-chart [fetchingObservable]="fetchCragGrades"></lc-grade-distribution-bar-chart>

    <ng-container *ngIf="crag.description">
      <h4>{{ t('description') }}:</h4>
      <section [innerHTML]="crag.description | sanitizeHtml" class="quill-content"></section>
    </ng-container>

    <lc-map [target]="crag"></lc-map>

    <ng-container *ngIf="!crag">

      <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="90%" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="87%" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="70%" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="65%" styleClass="mb-2"></p-skeleton>
      <p-skeleton width="45%" styleClass="mb-4"></p-skeleton>
      <p-skeleton height="4rem" styleClass="mb-4"></p-skeleton>
      <p-skeleton width="40%" height="4rem"></p-skeleton>

    </ng-container>

  </ng-container>

</ng-container>
