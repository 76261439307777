<ng-container *transloco="let t, read: 'coordinatesButton'">

  <p-splitButton label="{{t('coordinates')}}"
                 icon="pi pi-map-marker"
                 text
                 (onClick)="openCoordinates()"
                 [model]="items">
  </p-splitButton>

</ng-container>
