<ng-container *transloco="let t, read: 'area.area'">

  <ng-container *ngIf="area">

    <h4>{{t('gradeDistribution')}}:</h4>
    <lc-grade-distribution-bar-chart [fetchingObservable]="fetchAreaGrades"></lc-grade-distribution-bar-chart>

    <ng-container *ngIf="area.description">
      <h4>{{ t('description') }}:</h4>
    <section [innerHTML]="area.description | sanitizeHtml"   class="quill-content"></section>
    </ng-container>

    <lc-map [target]="area"></lc-map>

  </ng-container>

  <ng-container *ngIf="!area">

    <p-skeleton width="100%" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="90%" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="87%" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="70%" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="65%" styleClass="mb-2"></p-skeleton>
    <p-skeleton width="45%" styleClass="mb-4"></p-skeleton>
    <p-skeleton height="4rem" styleClass="mb-4"></p-skeleton>
    <p-skeleton width="40%" height="4rem"></p-skeleton>

  </ng-container>

</ng-container>
